import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layouts"
import Seo from "../components/seo"

const NotFoundPage: React.FC<PageProps> = ({ path }) => (
  <Layout>
    <Seo
      title="404: Not found"
      description="You just hit a route that doesn't exist..."
      path={path}
    />
    <section id="main">
      <div className="container">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
